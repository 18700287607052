import {
    USERS_LOADING,
    GET_USERS,
    UPDATE_USERS,
    LOGOUT_SUCCESS,
} from './types'
import axios from 'axios'
import { tokenConfig } from './configToken'
import { returnErrors, retrunSuccess } from './errorActions'
   
export const updateUser= (id, user) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        axios.put(`/api/users/change-status/${id}`, user, tokenConfig(getState))
        .then(res =>{
            dispatch({
            type: UPDATE_USERS
            })
            resolve(true)
        }
        )
        .catch(err => {
            console.log(err)
            if(err.response.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            else{
                dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_USER_ERROR'))
            }
            reject(err)
        })
    })
}

   
export const getAllUsers = (page, status, search) => (dispatch, getState) => {
    dispatch(setUser())
    return new Promise((resolve, reject) => {
        axios.get(`/api/users/get?page=${page}&query=${search}${status === 'all' ? '' : `&isBlocked=${status}`}`,
        tokenConfig(getState))
        .then(res =>{
            dispatch({
            type: GET_USERS,
            payload: res.data
            })
            resolve(true)
        })
        .catch(err => {
            if(err?.response?.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            reject(err)
        })
    })
}
   
export const setUser = () => {
    return {
        type: USERS_LOADING
    }
}