import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

function TimelineHeader({ name, btn_name, updateList, id }) {
  const history = useHistory();
  const [showAddBtn, setAddBtn] = useState(false);
  const [jobCategoryModal, showJobCategoryModal] = useState(false)

  useEffect(() => {
    if (
      history.location.pathname === "/admin/categories" ||
      history.location.pathname === "/admin/quotes" ||
      history.location.pathname === "/admin/managers" ||
      history.location.pathname === '/admin/notifications'
    ) {
      setAddBtn(true);
    }
  }, []);

  const handleAddClick = () => {
    if (history.location.pathname === "/admin/categories") {
      history.push("/admin/categories/add")
    } else if (history.location.pathname === "/admin/quotes") {
      history.push('/admin/quotes/add')
    } else if ( history.location.pathname === '/admin/notifications') {
      history.push('/admin/notifications/add')
    }
  };

  return (
    <>
      <div className="header header-dark bg-info-bh pb-40 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center pt-4">
              <Col lg="6">
                <h6 className="h1 text-white d-inline-block mb-0 mx-2">
                  {name}
                </h6>
              </Col>
              {showAddBtn && (
                <Col lg="6">
                  <div className="d-flex align-items-center justify-content-end">
                    <button onClick={handleAddClick} className="default-header-btn-style px-4">
                      {btn_name}
                    </button>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
