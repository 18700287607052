import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  Container,
  Row,
  CardBody,
  Col,
  Button,
  Form,
  Input,
  Label
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { connect } from 'react-redux';
import { getQuote, editQuote } from '../../actions/quoteActions'
import SpinnerLoader from "components/Misc/Spinner";
import { useHistory, useParams } from 'react-router-dom'
import toast, { Toaster } from "react-hot-toast";
import Datetime from "react-datetime"
import {
  updateToast,
  successToast,
  errorToast
} from "shared/constants";
import moment from "moment";

function EditQuote({ getQuote, editQuote }) {
    const [showSpinner, setSpinner] = useState(false);
    const [quote, setQuote] = useState('')
    const [writer, setWriter] = useState('')
    const [date, setDate] = useState(new Date())
    const [onDate, setOnDate] = useState(false)

    const params = useParams()
    const id = params.id

    useEffect(() => {
        getOneQuote(id)
    }, [])

    const getOneQuote = (id) => {
        getQuote(id)
        .then(data => {
            console.log(data)
            setQuote(data.quote.quote)
            setWriter(data.quote.writer)
            setOnDate(data.quote.date ? true : false)
            if(data.quote.date){
                setDate(moment(data.quote.date, 'MMM, DD'))
            }
        })
        .catch(err => {
            toast.error('Something went wrong', errorToast)
        })
    }

    const history = useHistory()

    const handleQuote = (e) => {
        setQuote(e.target.value)
    }

    const handleWriter = (e) => {
        setWriter(e.target.value)
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const formattedDate = moment(date).format('MMM, DD')
        
        const newQuote = {
        quote,
        writer,
        date: onDate ? formattedDate !== 'Invalid date' ? formattedDate : null : null    
        }
        setSpinner(true)
        console.log(newQuote)
        editQuote(id, newQuote)
        .then(res => {
          setSpinner(false)
          toast.success("Quote Updated successfully", successToast);
            history.push("/admin/quotes")
        })
        .catch (err => {
          console.log("Error: ", err)
          toast.error("Something wents wrong.", errorToast)
          setSpinner(false)
        })
    }

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <SimpleHeader
        name="Edit Quote"
        btn_name="Edit"
        updateList={() =>
          console.log("")
        }
      />
      <Container className="mt--4 ml-3 float-left ctgry-container">
        <Form onSubmit={(e) => onSubmit(e)}>
        <Row className="mt-2">
          <div className="col">
            <Card>
                <CardBody>
                    <Row>
                      <Col md={12}>
                        <Label className="secondary-label">Quote</Label>
                        <Input
                        type='textarea'
                        name="address"
                        placeholder="Quote"
                        className="input-secondary-style"
                        value={quote}
                        onChange={handleQuote}
                        required
                        />
                      </Col>
                      <Col md={12}>
                        <Label className="secondary-label">Writer</Label>
                        <Input
                        type='text'
                        name="address"
                        placeholder="writer"
                        className="input-secondary-style"
                        value={writer}
                        onChange={handleWriter}
                        required
                        />
                      </Col>
                      <Col md={12} className={"mt-3"}>
                        <Row>
                            <Col md={2}>
                            <div className="d-flex mt-3">
                                <label className="cstm-chkbox">
                                    <input
                                    type={'checkbox'}
                                    id='customCheck1'
                                    onChange={() => setOnDate(prev => !prev)}
                                    checked={onDate}
                                    />
                                </label>
                                <div className="ml-2" htmlFor={"customCheck1"}><span>On</span></div>
                            </div>
                            </Col>
                            <Col md={10}>
                                <Datetime
                                    className="cstm-dt"
                                    timeFormat={false}
                                    dateFormat={"MMM, DD"}
                                    value={date}
                                    onChange={(dt) => setDate(dt)}
                                    inputProps={{
                                        disabled: !onDate
                                    }}
                                />
                            </Col>
                        </Row>
                      </Col>
                    </Row>
                </CardBody>
            </Card>
          </div>
        </Row>
        <Row>
            <Col md={12}>
            <Button  className="default-button-background default-button-style" type="submit">
                Update
            </Button>
            </Col>
        </Row>
        </Form>
      </Container>
      <Toaster/>
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getQuote: (id) => dispatch(getQuote(id)),
    editQuote: (id, quote) => dispatch(editQuote(id, quote))
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      error: state.error
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditQuote);