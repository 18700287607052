import {
    UPDATE_QUOTE,
    QUOTES_LOADING,
    GET_QUOTES
} from '../actions/types'

const initialState = {
    quotes: [],
    currentPage: 1,
    totalPages: 0,
    loading: false
}

export default function categoryReducer(state=initialState, action) {
    switch(action.type) {
        case GET_QUOTES:
            return {
                ...state,
                quotes: action.payload.data.quotes,
                currentPage: Number(action.payload.data.metadata.currentPage),
                totalPages: Number(action.payload.data.metadata.totalPages),
                loading: false
            };
        case UPDATE_QUOTE:{
            return {
                ...state
            }
        };
        case QUOTES_LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            return state
    }
}