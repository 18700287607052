import Login from "views/auth/Login.js";
import ForgotPassword from "views/auth/ForgotPassword";

import EditCategory from "views/category/EditCategory";

import Categories from "views/category/Categories";
import ResetPassword from "views/auth/ResetPassword";
import EmailSent from "views/auth/EmailSent";

import ManageUsers from "views/manageUsers/ManageUsers";

import ChangePassword from "views/auth/ChangePassword";

import AddCategory from "views/category/AddCategory";
import Quotes from "views/quotes/Quotes";
import AddQuote from "views/quotes/AddQuote";
import EditQuote from "views/quotes/EditQuote";
import Notifications from "views/notifications/Notifications";
import AddEditNotification from "views/notifications/AddEditNotification";

export const routes = [
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    miniName: "FP",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/email-sent",
    name: "Email Sent",
    miniName: "ES",
    component: EmailSent,
    layout: "/auth",
  },
  {
    path: "/reset-password/:id",
    name: "Reset Password",
    miniName: "RP",
    component: ResetPassword,
    layout: "/auth",
  },
];

export const sidebarRoutes = [
  {
    path: "/manage-users",
    name: "Manage Users",
    icon: "/manage-user",
    showOnSidebar: true,
    component: ManageUsers,
    layout: "/admin",
  },
  {
    path: "/change-password",
    showOnSidebar: false,
    component: ChangePassword,
    layout: "/admin",
  },
  {
    path: "/categories/add",
    showOnSidebar: false,
    component: AddCategory,
    layout: "/admin",
  },
  {
    path: "/categories/edit/:id",
    showOnSidebar: false,
    component: EditCategory,
    layout: "/admin",
  },
  {
    path: "/categories",
    name: "Daily Templates",
    icon: "/daily-template",
    showOnSidebar: true,
    component: Categories,
    layout: "/admin",
  },
  {
    path: "/quotes/add",
    showOnSidebar: false,
    component: AddQuote,
    layout: "/admin",
  },
  {
    path: "/quotes/edit/:id",
    showOnSidebar: false,
    component: EditQuote,
    layout: "/admin",
  },
  {
    path: "/quotes",
    name: "Manage Quotes",
    icon: "/manage-quote",
    showOnSidebar: true,
    component: Quotes,
    layout: "/admin",
  },
  {
    path: "/notifications/edit/:id",
    showOnSidebar: false,
    component: AddEditNotification,
    layout: "/admin",
  },
  {
    path: "/notifications/add",
    showOnSidebar: false,
    component: AddEditNotification,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Manage Notifications",
    icon: "/notifications",
    showOnSidebar: true,
    component: Notifications,
    layout: "/admin",
  }
  
];
