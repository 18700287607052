import {createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import { composeWithDevTools } from '@redux-devtools/extension'
import { persistStore } from 'redux-persist'
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync'

const initialState = []
const middleware = [thunk, createStateSyncMiddleware()]

export const store = createStore(rootReducer, initialState, composeWithDevTools(
    applyMiddleware(...middleware)
));


initMessageListener(store)

export const persistor = persistStore(store)

export default { store, persistor };