import { CLEAR_ERROR, GET_ERROR, GET_SUCCESS } from '../actions/types'

const initialState = {
    msg: {},
    status: null,
    id: null
}

export default function errorReducer(state=initialState, action){
    switch(action.type){
        case CLEAR_ERROR:
            return{
                msg: {},
                status: null,
                id: null
            };
        case GET_SUCCESS:
        case GET_ERROR:
            return{
                msg: action.payload.msg,
                status: action.payload.status,
                id: action.payload.id
            };
        default:
            return state;
    }
}