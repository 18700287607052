import {
 LOGIN_FAIL,
 LOGIN_SUCCESS,
 LOGOUT_SUCCESS,
 USER_LOADING,
 FORGOT_PASSWORD_FAIL,
 FORGOT_PASSWORD_SUCCESS,
 TOKEN_INVALID,
 TOKEN_VALID
} from './types'
import axios from 'axios'
import { tokenConfig } from './configToken'
import { returnErrors, retrunSuccess } from './errorActions'
const baseURL = process.env.REACT_APP_BACKEND_URL;

export const login = ({email, password}) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: USER_LOADING
        })
        const config = { 
            headers: {
                'Content-Type': 'application/json'
            }
        }

        // request body
        const body = JSON.stringify({ email, password})

        axios.post('/api/users/login', body, config)
        .then(res =>{
            dispatch({
            type: LOGIN_SUCCESS,
            payload: {data: res.data, headers: res.headers}
            })
            resolve(true)
        })
        .catch(err => {
            console.log(err)
            dispatch(returnErrors(err.response, err.response, 'LOGIN_FAIL'))
            dispatch({
                type: LOGIN_FAIL
            })
            reject(err)
        })
    })
}

export const forgot_password = (email) => dispatch => {
    return new Promise((resolve, reject) => {
        const config = { 
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios.post(`/api/users/forgotPassword`, {email}, config)
        .then(res =>{ 
            dispatch(retrunSuccess(res.data, res.status, 'FORGOT_SUCCESS'))
            dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: res.data
            })
            resolve(true)
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'FORGOT_FAIL'))
            dispatch({
                type: FORGOT_PASSWORD_FAIL
            })
            reject(err)
        })
    })
}

export const validate_reset_link = (token) => dispatch => {
    return new Promise((resolve, reject) => {
        const config = { 
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios.post(`${baseURL}/auth/validate-pass-reset-link`, {token}, config)
        .then(res =>{
            dispatch({
            type: TOKEN_VALID
            })
            resolve(true)
        })
        .catch(err => {
            dispatch({
                type: TOKEN_INVALID
            })
            reject(err)
        })
    })
}

export const changePasswordWithLink = (password, confirmPassword, token) => dispatch => {
    return new Promise((resolve, reject) => {
        const config = { 
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios.post(`/api/users/resetPassword/${token}`, {password, confirmPassword}, config)
        .then(res =>{
            dispatch({
            type: TOKEN_VALID
            })
            resolve(true)
        })
        .catch(err => {
            dispatch({
                type: TOKEN_INVALID
            })
            reject(err)
        })
    })
}

export const changePassword = (passwords) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/users/change-password`, passwords, tokenConfig(getState))
        .then(res =>{
            resolve(res.data)
        }
        )
        .catch(err => {
            console.log(err)
            if(err.response.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            else{
                dispatch(returnErrors(err.response.data, err.response.status, 'CHANGE_PASSWORD_ERROR'))
            }
            reject(err)
        })
    })
}

export const logout = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/users/logout`, {}, tokenConfig(getState))
        .then(res =>{
            dispatch({
                type: LOGOUT_SUCCESS
            })
            resolve(true)
        }
        )
        .catch(err => {
            console.log(err)
            if(err.response.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            else{
                dispatch(returnErrors(err.response.data, err.response.status, 'CHANGE_PASSWORD_ERROR'))
            }
            reject(err)
        })
    })
}