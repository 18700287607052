import {
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGOUT_SUCCESS,
    TOKEN_INVALID,
    TOKEN_VALID,
    CHECKING_TOKEN
} from '../actions/types'

const initialState = {
    access_token: localStorage.getItem('access_token'),
    isAuthenticated: null,
    isLoading: false,
    isTokenCheckLoading: false,
    isTokenValid: false,
    isTokenCheck: false,
    user: {}
}

export default function authReducer(state=initialState, action){
    switch(action.type){
        case LOGIN_SUCCESS:
            localStorage.setItem('access_token', action.payload.headers.access_token)
            console.log(action.payload.data)
            return{
                ...state,
                user: action.payload.data.data.user,
                access_token: action.payload.headers.access_token,
                isAuthenticated: true,
                isLoading: false,
            };
        case USER_LOADED:
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                isLoading: false
            };
        case USER_LOADING:
            return{
                ...state,
                isLoading: true
            };
        case LOGIN_FAIL:
        case AUTH_ERROR:
        case LOGOUT_SUCCESS:
            localStorage.removeItem('access_token')
            sessionStorage.clear()
            return{
                ...state,
                isLoading: false,
                isAuthenticated: false,
                access_token: null,
                user: {}
                
            };
        case CHECKING_TOKEN:
            return{
                ...state,
                isTokenCheckLoading: true
            };
        case TOKEN_VALID:
            return{
                ...state,
                isTokenCheckLoading: false,
                isTokenValid: true,
                isTokenCheck: true
            }
        case TOKEN_INVALID:
            return{
                ...state,
                isTokenCheckLoading: false,
                isTokenValid: false,
                isTokenCheck: true
            }
        default:
            return state;
    }
}