import {  GET_ERROR, CLEAR_ERROR, GET_SUCCESS } from './types'

// return errors

export const returnErrors = (msg, status, id=null) => {
    return{
        type: GET_ERROR,
        payload: { msg, status, id}
    }
}

// return success message
export const retrunSuccess = (msg, status, id=null) => {
    return{
        type: GET_SUCCESS,
        payload: {msg, status, id}
    }
}

// clear errors

export const clearErrors = () => {
    return {
        type: CLEAR_ERROR
    }
}