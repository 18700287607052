import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { errorToast, successToast } from "shared/constants";
import SpinnerLoader from "components/Misc/Spinner";

function EmailSent() {
  const history = useHistory();

  return (
    <>
      <div>
        <Container>
          <Card className="bg-default-login-card border-0 mb-0">
            <CardBody className="px-lg-6 py-lg-3">
              <div className="d-flex justify-content-center mt-5">
                <img src="../assets/image/dd-logo.svg"/>
              </div>
              <div className="text-center text-muted mt-4 mb-4">
                <h1 className="default-color gotham-medium">Email Sent</h1>
              </div>
              <div className="email-sent-text">
                  <small>
                      Check your email, We have sent you the necessary instructions to recover your password.
                  </small>
              </div>
              <Form role="form">
                <div className="text-center pt-4">
                  <small
                    onClick={(e) => history.push("/auth/login")}
                    className="black-color px-2 small-text py-2"
                    role={"button"}
                  >
                    Back to login
                  </small>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
      <Toaster />
    </>
  );
}

export default EmailSent;
