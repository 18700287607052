import {
    UPDATE_NOTIFICATION,
    NOTIFICATIONS_LOADING,
    GET_NOTIFICATIONS
} from '../actions/types'

const initialState = {
    notifications: [],
    currentPage: 1,
    totalPages: 0,
    loading: false
}

export default function categoryReducer(state=initialState, action) {
    switch(action.type) {
        case GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload.data.notifications,
                currentPage: Number(action.payload.data.metadata.currentPage),
                totalPages: Number(action.payload.data.metadata.totalPages),
                loading: false
            };
        case UPDATE_NOTIFICATION:{
            return {
                ...state
            }
        };
        case NOTIFICATIONS_LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            return state
    }
}