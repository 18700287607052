import React, { useState } from "react";
import {
  Card,
  Container,
  Row,
  Input,
  CardBody,
  Col,
  Form,
  Button,
  InputGroup,
  Label
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { connect } from 'react-redux';
import { addNotification, getOneNotification, updateNotification } from '../../actions/notificationActions'
import SpinnerLoader from "components/Misc/Spinner";
import toast, { Toaster } from "react-hot-toast";
import Select2 from "react-select2-wrapper";
import { useHistory, useParams } from 'react-router-dom'
import ReactDateTime from 'react-datetime'
import {
  successToast,
  errorToast,
  NOTIFICATION_SEND_TO_ENUM,
  NOTIFICATION_STATUS,
  DEFAULT_DATE_FORMAT
} from "shared/constants";
import { useEffect } from "react";
import moment from "moment";

function AddNotification({ addNotification, getOneNotification, updateNotification }) {
  const [showSpinner, setSpinner] = useState(false);
  const [title, setTitle] = useState('')
  const [schedule, setSchedule] = useState(NOTIFICATION_STATUS.sent)
  const [scheduleDate, setScheduleDate] = useState(moment().utc().format(DEFAULT_DATE_FORMAT))
  const [description, setDescription] = useState('')
  const [time, setTime] = useState('12:00 AM')
  const [timeSlots, setTimeSlots] = useState([])
  const [isScheduled, setIsScheduled] = useState(false)
  const [descriptionValidation, showDescriptionMessage] = useState(false)

  const history = useHistory()

  const params = useParams()
  const id = params.id

  useEffect(() => {
    const slots = []
    const start = moment().utc().startOf('D')
    const end = moment().utc().endOf('day')

    while(start <= end) {
      slots.push({text: start.format("hh:mm A"), id: start.format("hh:mm A")})
      start.add(15, 'minutes')
    }
    setTimeSlots(slots)

    if(history.location.pathname.startsWith("/admin/notifications/edit")){
      fetchNotification(id)
    }
  }, [])

  const fetchNotification = async (id) => {
    setSpinner(true)
    getOneNotification(id)
    .then(data => {
      setTime(data?.notification?.time)
      setScheduleDate(data?.notification?.date)
      setTitle(data?.notification?.title)
      setDescription(data?.notification?.description)
      setIsScheduled(true)
      setSchedule(NOTIFICATION_STATUS.scheduled)
      setSpinner(false)
    })
    .catch(err => {
      setSpinner(false)
      history.push("/admin/notifications")
      toast.error("Something went wrong!", errorToast)
    })
  }

  const handleScheduleDateChange = (val) => {
    setScheduleDate(val.format(DEFAULT_DATE_FORMAT))
  }

  const handleTitleChange = (val) => {
    setTitle(val)
  }

  const handleDescriptionChange = (val) => {
    if(description.length >= 200){
      if(description.length >= val.length){
        setDescription(val)
      }
      else{
        showDescriptionMessage(true)
      }
      
    }
    else{
      showDescriptionMessage(false)
      setDescription(val)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSpinner(true)
    let notificationObject;
    if(isScheduled){
      notificationObject = {
        title,
        description,
        date: scheduleDate,
        time,
        sendNow: false
      }
    }
    else{
      notificationObject = {
        title,
        description,
        sendNow: true
      }
    }
    if(history.location.pathname === "/admin/notifications/add"){
      addNotification(notificationObject)
      .then(res => {
        setSpinner(false)
        toast.success("Notification added Successfuly", successToast);
        history.push('/admin/notifications')
      })
      .catch(err => {
        setSpinner(false)
        toast.error("Something went wrong", errorToast);
      })
    }

    if(history.location.pathname.startsWith("/admin/notifications/edit")){
      updateNotification(id, notificationObject)
      .then(res => {
        setSpinner(false)
        toast.success("Notification updated Successfuly", successToast);
        history.push('/admin/notifications')
      })
      .catch(err => {
        setSpinner(false)
        toast.error("Something went wrong", errorToast);
      })
    }
  }

  const handleScheduleCheck = (val) => {
    if(NOTIFICATION_STATUS.scheduled === val) {
      setIsScheduled(true)
    }
    else{
      setIsScheduled(false)
    }
      setSchedule(val)
  }

  const handleTimeChange = (val) => {
    console.log(val)
    setTime(val)
  }

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <SimpleHeader
        name={`${history.location.pathname.startsWith("/admin/notifications/add") ? "Send Notification" : "Edit Notification"}`}
      />
      <Container className="mt--4 float-left ml-3 ctgry-container">
        <Row>
          <div className="col">
          <Form action='submit' onSubmit={(e) => handleSubmit(e)}>
            <Card>
                <CardBody>
                  <Row className="px-3 pt-4">
                      <Col lg="12">
                          <Label className="secondary-label">Title</Label>
                          <Input
                          placeholder="Title"
                          className="input-primary-style"
                          type="text"
                          value={title}
                          onChange={(e) => handleTitleChange(e.target.value)}
                          required
                          />
                      </Col>
                  </Row>
                  <Row className="px-3 pt-4">
                      <Col lg='12'>
                        <Label className="secondary-label">Description</Label>
                        <Input
                        placeholder="Message description"
                        className="mb-1 input-primary-style"
                        type="textarea"
                        row={5}
                        value={description}
                        onChange={(e) => handleDescriptionChange(e.target.value)}
                        required
                        />
                        {descriptionValidation && <small className="ml-2 text-danger">Reached max limit (200 characters)</small>}
                      </Col>
                  </Row>
                  <Row className="px-3 pt-4">
                      <Col lg="12">
                        <Label className="secondary-label">Schedule</Label>
                      </Col>
                      <Col lg="6">
                          <div 
                          className={`
                          custom-control 
                          custom-radio 
                          custom-radio-style 
                          ${schedule === NOTIFICATION_STATUS.sent ? 'custom-radio-checked' : ''}`}
                          >
                              <Input
                              className="custom-control-input"
                              id="send-now"
                              name="schedule"
                              type="radio"
                              value={NOTIFICATION_STATUS.sent}
                              onChange={(e) => handleScheduleCheck(e.target.value)}
                              checked={schedule === NOTIFICATION_STATUS.sent}
                              />
                              <label className="custom-control-label label-txt w-100" htmlFor="send-now">
                                  Send Now
                              </label>
                          </div>
                      </Col>
                      <Col lg="6">
                          <div 
                          className={`
                          custom-control 
                          custom-radio 
                          custom-radio-style 
                          ${schedule === NOTIFICATION_STATUS.scheduled ? 'custom-radio-checked' : ''}`}
                          >
                              <Input
                              className="custom-control-input"
                              id="schedule"
                              name="schedule"
                              type="radio"
                              value={NOTIFICATION_STATUS.scheduled}
                              onChange={(e) => handleScheduleCheck(e.target.value)}
                              checked={schedule === NOTIFICATION_STATUS.scheduled}
                              />
                              <label className="custom-control-label label-txt w-100" htmlFor="schedule">
                                  Schedule
                              </label>
                          </div>
                      </Col>
                  </Row>
                  {isScheduled &&
                    <Row className="px-3 pt-4">
                        <Col lg="6">
                            <Label className="secondary-label">Date</Label>
                            <InputGroup className="d-block position-relative">
                              <ReactDateTime
                              timeFormat={false}
                              dateFormat={DEFAULT_DATE_FORMAT}
                              value={scheduleDate}
                              onChange={(e) => handleScheduleDateChange(e)}
                              inputProps={{
                                placeholder: DEFAULT_DATE_FORMAT,
                                timeFormat: false,
                                required: true,
                                readOnly: true
                              }}
                              />
                              <i className="fas fa-chevron-down fa-12x position-absolute time-icon"></i>
                            </InputGroup>
                        </Col>
                        <Col lg="6">
                          <Label className="secondary-label">Time</Label>
                          <InputGroup className="position-relative">
                            <Select2
                              value={time}
                              onSelect={(e) => handleTimeChange(e.params.data.id)}
                              data={timeSlots}
                              options= {{
                                minimumResultsForSearch: Infinity
                              }}
                            />
                            <i className="fas fa-chevron-down fa-12x position-absolute time-icon"></i>
                          </InputGroup>
                        </Col>
                    </Row>
                  }
                </CardBody>
            </Card>
            <Row className="px-3">
              <Col lg="6">
                <Button  className="default-button-background default-button-style" type="submit" >
                  {history.location.pathname.startsWith("/admin/notifications/add") ? "Send" : "Update"}
                </Button>
              </Col>
            </Row>
            </Form>
          </div>
        </Row>
      </Container>
      <Toaster/>
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    addNotification: (notification) => dispatch(addNotification(notification)),
    getOneNotification: (id) => dispatch(getOneNotification(id)),
    updateNotification: (id, notification) => dispatch(updateNotification(id, notification))
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      error: state.error
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNotification);