import {
    UPDATE_CATEGORY,
    CATEGORY_LOADING,
    GET_CATEGORIES
} from '../actions/types'

const initialState = {
    categories: [],
    currentPage: 1,
    totalPages: 0,
    loading: false
}

export default function categoryReducer(state=initialState, action) {
    switch(action.type) {
        case GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload.data.categories,
                currentPage: Number(action.payload.data.meta.currentPage),
                totalPages: Number(action.payload.data.meta.totalPages),
                loading: false
            };
        case UPDATE_CATEGORY:{
            return {
                ...state
            }
        };
        case CATEGORY_LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            return state
    }
}