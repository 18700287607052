import {
    LOGOUT_SUCCESS,
    ADD_NOTIFICATION,
    GET_NOTIFICATIONS,
    NOTIFICATIONS_LOADING,
} from './types'
import axios from 'axios'
import { tokenConfig } from './configToken'
import { returnErrors, retrunSuccess } from './errorActions'
const baseURL = process.env.REACT_APP_BACKEND_URL;
   
export const addNotification = (notification) => (dispatch, getState) => {
    console.log(notification)
    return new Promise((resolve, reject) => {
        axios.post(`/api/notifications/add`, notification, tokenConfig(getState))
        .then(res =>{
            dispatch(retrunSuccess(res.data, res.status, 'ADD_NOTIFICATION_SUCCESS'))
            dispatch({
            type: ADD_NOTIFICATION,
            payload: res.data.notification
            })
            resolve(true)
        }
        )
        .catch(err => {
            if(err.response.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            else{
            dispatch(returnErrors(err.response.data, err.response.status, 'ADD_NOTIFICATION_ERROR'))
            }
            reject(err)
        })
    })
}
   
export const updateNotification = (id, notification) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        axios.put(`/api/notifications/edit/${id}`, notification, tokenConfig(getState))
        .then(res =>{
            dispatch(retrunSuccess(res.data, res.status, 'UPDATE_NOTIFICATION_SUCCESS'))
            resolve(true)
        }
        )
        .catch(err => {
            if(err.response.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            else{
                dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_NOTIFICATION_ERROR'))
            }
            reject(err)
        })
    })
}

export const deleteNotification = (id) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/notifications/delete/${id}`, tokenConfig(getState))
        .then(res =>{
            dispatch(retrunSuccess(res.data, res.status, 'DELETE_NOTIFICATION_SUCCESS'))
            resolve(true)
        }
        )
        .catch(err => {
            if(err.response.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            else{
                dispatch(returnErrors(err.response.data, err.response.status, 'DELETE_NOTIFICATION_ERROR'))
            }
            reject(err)
        })
    })
}

export const sendNotificationNow = (id, notification) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        axios.put(`${baseURL}/notification/send-now/${id}`, notification, tokenConfig(getState))
        .then(res =>{
            dispatch(retrunSuccess(res.data, res.status, 'UPDATE_NOTIFICATION_SUCCESS'))
            resolve(true)
        }
        )
        .catch(err => {
            if(err.response.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            else{
                dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_NOTIFICATION_ERROR'))
            }
            reject(err)
        })
    })
}

export const getAllNotifications = (page, search) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        dispatch(setManagerLoading());
        axios.get(`/api/notifications/get?page=${page}&query=${search}`, tokenConfig(getState))
        .then(res =>{
            dispatch({
            type: GET_NOTIFICATIONS,
            payload: res.data
            })
            resolve(true)
        })
        .catch(err => {
            if(err.response.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            reject(err)
        })
    })
}
   
export const getOneNotification = (id) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        axios.get(`/api/notifications/get/${id}`, tokenConfig(getState))
        .then(res => {
            resolve(res.data?.data)
        })
        .catch(err => {
            if(err.response.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            else{
                reject(err)
            }
        })
    })
}

   
export const setManagerLoading = () => {
    return {
        type: NOTIFICATIONS_LOADING
    }
}