import React, { useState, useCallback } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Badge
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SpinnerLoader from "components/Misc/Spinner";
import { getAllQuotes, deleteQuote } from '../../actions/quoteActions'
import { connect } from 'react-redux';
import { errorToast, USER_STATUSES, successToast } from '../../shared/constants'
import { useHistory } from 'react-router-dom'
import Select2 from "react-select2-wrapper";
import toast, { Toaster } from "react-hot-toast";

function ManageQuotes({getAllQuotes, quotes, deleteQuote}) {
  const [totalPages, setTotalPages] = useState(quotes?.totalPages)
  const [currentPage, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [searchFocus, setSearchFocus] = useState(false)

  const history = useHistory();

  React.useEffect(() => {
    fetchQuotes(
      {
        page: currentPage,
        search: search
      }
    )
  }, []);

  const fetchQuotes = async (body) => {
    setSpinner(true);
    getAllQuotes(body.page, body.search)
    .then(data => {
      setPage(quotes.currentPage)
      setTotalPages(quotes.totalPages)
      setSpinner(false)
    })
    .catch(err => {
      toast.error('Something went wrong!', errorToast)
      console.log("Error: ", err)
    })
  }

  const pages = () => {
    let pagesArr = [];
    for (let i = 1; i <= quotes.totalPages; i++) {
      pagesArr.push(
        <PaginationItem className={quotes.currentPage === i ? "active" : ""}>
          <PaginationLink onClick={(e) => handlePageClick(e, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pagesArr;
  };

  const handlePageClick = (e, pageNumber) => {
    e.preventDefault();
    fetchQuotes({page: pageNumber, search: search})
    setPage(pageNumber);
  };

  const handleSearch = (val) => {
    setSearch(val);
    fetchQuotes({page: currentPage, search: val})
  };

  const handleDeleteQuote = (e, id) => {
    setSpinner(true)
    deleteQuote(id)
    .then(data => {
      toast.success("Quote deleted successfully.", successToast)
      fetchQuotes({page: currentPage, search: search})
      setSpinner(false)
    })
    .catch(err => {
      toast.error("Something went wrong.", errorToast)
    })
  }

  const handleEditQuote = async (e, id) => {
    history.push(`/admin/quotes/edit/${id}`)
  }

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <SimpleHeader
        name="Manage Quotes"
        btn_name="Add New"
        updateList={() =>
          console.log("")
        }
      />
      <Container className="mt--4" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <div className="d-flex align-content-center justify-content-between">
                  <div className="search-field">
                    <InputGroup className="search-group">
                      <InputGroupText className={` search-icon`}>
                        <i className="fas fa-search fa-12x"></i>
                      </InputGroupText>
                      <Input
                        className="d-inline-block searchBox"
                        placeholder="Search..."
                        type="text"
                        value={search}
                        onFocus={() => setSearchFocus(true)}
                        onBlur={() => setSearchFocus(false)}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                </div>
              </CardHeader>
              <div className="table-responsive">
                <Table className="dataTable align-items-center">
                  <thead className="thead-bh icon-color-light">
                    <tr>
                      <th className="px-5 w-10" scope="col">
                        Sr#
                      </th>
                      <th className="px-0 w-15" scope="col">
                        Quote
                      </th>
                      <th className="px-0 w-10" scope="col">
                        Writer
                      </th>
                      <th className="px-0 w-10" scope="col">
                        Date
                      </th>
                      <th className="px-0 w-5" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {quotes?.quotes?.map((quote, index) => (
                      <tr key={index}>
                        <td className="pl-5 ">
                          <div className="tbl-txt d-flex justify-content-start align-items-center">
                            {(quotes.currentPage*10)-(10-index-1)}
                          </div>
                        </td>
                        <td className="overflowStyle pl-0 pr-4 tbl-txt">
                            <span className="pl-2 tbl-txt">{quote?.quote}</span>
                        </td>
                        <td className="pl-0 pr-4 overflowStyle tbl-txt">
                          {quote?.writer}
                        </td>
                        <td className="pl-0 pr-4 overflowStyle tbl-txt">
                          {quote?.date ? quote?.date : '-'}
                        </td>
                        <td className="actionDropdown px-0">
                        <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light action-bg"
                              color=""
                              role="button"
                              size="sm"
                            >
                              <i className="fas fa-ellipsis-h action-dot" />
                            </DropdownToggle>
                            <DropdownMenu right className="mw-210">
                              <DropdownItem
                              onClick={(e) => handleEditQuote(e, quote._id)}
                              >
                                <div className="d-flex align-items-center justify-content-start">
                                  <img src="/edit.svg" size='15px' alt="i"/>
                                  <div className="pl-2 action-txt">Edit</div>
                                </div>
                              </DropdownItem>
                              <DropdownItem divider/>
                              <DropdownItem
                                onClick={(e) => handleDeleteQuote(e, quote._id)}
                                >
                                <div className="d-flex align-items-center justify-content-start">
                                  <span className="x-icon">X</span>
                                  <div className="pl-2 action-txt">Delete</div>
                                </div>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                    <tr></tr>
                  </tbody>
                </Table>
                {!showSpinner && !quotes?.quotes?.length && (
                  <div className="d-flex align-items-center justify-content-center mb-3">
                    No records found
                  </div>
                )}
              </div>
              <CardFooter className="py-4">
                <nav
                  className="d-flex align-items-center justify-content-between"
                  aria-label="..."
                >
                  <div className="pageEntry">
                  </div>
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                      className={quotes?.currentPage === 1 ? "disabled" : ""}
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, quotes?.currentPage - 1)}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {pages().map((page, index) => (
                      <div key={index}>{page}</div>
                    ))}
                    <PaginationItem
                      className={
                        quotes.currentPage >= quotes.totalPages
                          ? "disabled"
                          : ""
                      }
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, quotes.currentPage + 1)}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Toaster/>
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getAllQuotes: (page, search ) => dispatch(getAllQuotes(page, search)),
    updateUser: (id, body) => dispatch(updateUser(id, body)),
    deleteQuote: (id) => dispatch(deleteQuote(id))
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      quotes: state.quotes
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageQuotes);
