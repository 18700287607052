// Setup config/header an token
export const tokenConfig = getState => {
    // Get token from localstorage
const access_token = getState().auth.access_token;

// Headers
const config = {
    headers: {
        "Content-Type": "application/json",
        "platform" : "react-app"
    }
}

if(access_token) {
    config.headers['access_token'] = "Bearer "+access_token
}

return config;

}
