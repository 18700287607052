import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Badge
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SpinnerLoader from "components/Misc/Spinner";
import { getAllCategories, updateCategory, deleteCategory } from '../../actions/categoryActions'
import { connect } from 'react-redux';
import { errorToast, CATEGORY_STATUSES, successToast } from '../../shared/constants'
import { useHistory } from 'react-router-dom'
import Select2 from "react-select2-wrapper";
import toast, { Toaster } from "react-hot-toast";

function Categories({getAllCategories, categories, updateCategory, deleteCategory}) {
  const [totalPages, setTotalPages] = useState(categories?.totalPages)
  const [currentPage, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [searchFocus, setSearchFocus] = useState(false)
  const [filter, setFilter] = useState('all')
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const history = useHistory();

  React.useEffect(() => {
    fetchCategories(
      {
        page: currentPage,
        active: filter,
        search: search
      }
    )
  }, []);

  const fetchCategories = async (body) => {
    setSpinner(true);
    getAllCategories(body.page, body.active, body.search)
    .then(data => {
      setPage(categories.currentPage)
      setTotalPages(categories.totalPages)
      setSpinner(false)
    })
    .catch(err => {
      console.log("Error: ", err)
    })
  }

  const pages = () => {
    let pagesArr = [];
    for (let i = 1; i <= categories.totalPages; i++) {
      pagesArr.push(
        <PaginationItem className={categories.currentPage === i ? "active" : ""}>
          <PaginationLink onClick={(e) => handlePageClick(e, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );updateCategory
    }
    return pagesArr;
  };

  const handlePageClick = (e, pageNumber) => {
    e.preventDefault();
    fetchCategories({page: pageNumber, active: filter, search: search})
    setPage(pageNumber);
  };

  const handleSearch = (val) => {
    setSearch(val);
    fetchCategories({page: currentPage, active: filter, search: val})
  };

  const handleEditCategory = (e, id) => {
    history.push(`/admin/categories/edit/${id}`)
  }

  const handleDeleteCategory = async (e, id) => {
    setSpinner(true)
    deleteCategory(id)
    .then(res => {
      setSpinner(false)
      toast.success("Category Deleted Successfully", successToast)
      fetchCategories(
        {
          page: currentPage,
          active: filter,
          search: search
        }
      )
    })
    .catch(err => {
      setSpinner(false)
      toast.error('Unable to delete category', errorToast)
    })
  }

  const handleFilterChange = useCallback(val => {
    console.log(val)
    setFilter(val)
    fetchCategories({page: currentPage, active: val, search: search})
  }, [filter])

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <SimpleHeader
        name="Daily Template"
        btn_name="Add New"
        updateList={() =>
          console.log("")
        }
      />
      <Container className="mt--4" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <div className="d-flex align-content-center justify-content-between">
                  <div className="search-field">
                    <InputGroup className="search-group">
                      <InputGroupText className={` search-icon`}>
                        <i className="fas fa-search fa-12x"></i>
                      </InputGroupText>
                      <Input
                        className="d-inline-block searchBox"
                        placeholder="Search..."
                        type="text"
                        value={search}
                        onFocus={() => setSearchFocus(true)}
                        onBlur={() => setSearchFocus(false)}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup className="ml-3 filter">
                      <Select2
                        value={filter}
                        onSelect={(e) => handleFilterChange(e.params.data.id)}
                        data={[
                          {text: 'All', id: 'all'},
                          {text: 'Standard', id: CATEGORY_STATUSES.standard},
                          {text: 'Religious', id: CATEGORY_STATUSES.religious}
                        ]}
                        options= {{
                          minimumResultsForSearch: Infinity
                        }}
                      />
                      <i className="fas fa-chevron-down fa-12x filter-icon"></i>
                    </InputGroup>
                  </div>
                </div>
              </CardHeader>
              <div className="table-responsive">
                <Table className="dataTable align-items-center">
                  <thead className="thead-bh icon-color-light">
                    <tr>
                      <th className="px-5 w-10" scope="col">
                        Sr#
                      </th>
                      <th className="px-0 w-15" scope="col">
                        Category
                      </th>
                      <th className="px-0 w-10" scope="col">
                        Planner
                      </th>
                      <th className="px-0 w-5" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {categories?.categories?.map((category, index) => (
                      <tr key={index}>
                        <td className="pl-5 ">
                          <div className="tbl-txt d-flex justify-content-start align-items-center">
                            {(categories.currentPage*10)-(10-index-1)}
                          </div>
                        </td>
                        <td className="default-color overflowStyle pl-0 pr-4 tbl-txt cursor-pointer">
                          <div className="d-flex justify-content-start align-items-center">
                            <img src={category?.categoryType?.url} className='tbl-img'/>
                            <span className="pl-2 tbl-txt">{category?.title}</span>
                          </div>
                        </td>
                        <td className="pl-0 pr-4 overflowStyle tbl-txt">
                          {category?.isStandard && <span className="dflt-badge tbl-txt mx-1">Standard</span>}
                          {category?.isReligious && <span className="dflt-badge tbl-txt mx-1">Religious</span>}
                        </td>
                        <td className="actionDropdown px-0">
                        <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light action-bg"
                              color=""
                              role="button"
                              size="sm"
                            >
                              <i className="fas fa-ellipsis-h action-dot" />
                            </DropdownToggle>
                            <DropdownMenu right className="mw-210">
                              <DropdownItem
                              onClick={(e) => handleEditCategory(e, category._id)}
                              >
                                <div className="d-flex align-items-center justify-content-start">
                                  <img src="/edit.svg" size='15px' alt="i"/>
                                  <div className="pl-2 action-txt">Edit</div>
                                </div>
                              </DropdownItem>
                              <DropdownItem divider/>
                              <DropdownItem
                              onClick={(e) => handleDeleteCategory(e, category._id)}
                              >
                                <div className="d-flex align-items-center justify-content-start">
                                  <span className="x-icon">X</span>
                                  <div className="pl-2 action-txt">Delete</div>
                                </div>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                    <tr></tr>
                  </tbody>
                </Table>
                {!showSpinner && !categories?.categories?.length && (
                  <div className="d-flex align-items-center justify-content-center mb-3">
                    No records found
                  </div>
                )}
              </div>
              <CardFooter className="py-4">
                <nav
                  className="d-flex align-items-center justify-content-between"
                  aria-label="..."
                >
                  <div className="pageEntry">
                  </div>
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                      className={categories.currentPage === 1 ? "disabled" : ""}
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, categories.currentPage - 1)}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {pages().map((page, index) => (
                      <div key={index}>{page}</div>
                    ))}
                    <PaginationItem
                      className={
                        categories.currentPage >= categories.totalPages
                          ? "disabled"
                          : ""
                      }
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, categories.currentPage + 1)}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Toaster/>
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getAllCategories: (page, active, search, startDate, endDate) => dispatch(getAllCategories(page, active, search, startDate, endDate)),
    updateCategory: (id, category) => dispatch(updateCategory(id, category)),
    deleteCategory: (id) => dispatch(deleteCategory(id))
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      categories: state.categories
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
