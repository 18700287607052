import {
  CATEGORY_LOADING,
  GET_CATEGORIES,
  UPDATE_CATEGORY,
  LOGOUT_SUCCESS,
  ADD_CATEGORY,
} from './types';
import axios from 'axios';
import { tokenConfig } from './configToken';
import { returnErrors, retrunSuccess } from './errorActions';

export const updateCategory = (id, category) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/category/edit/${id}`, category, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: UPDATE_CATEGORY,
        });
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          dispatch({
            type: LOGOUT_SUCCESS,
          });
        } else {
          dispatch(
            returnErrors(
              err.response.data,
              err.response.status,
              'UPDATE_CATEGORY_ERROR'
            )
          );
        }
        reject(err);
      });
  });
};

export const addCategory = (category) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/category/add`, category, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: ADD_CATEGORY,
        });
        resolve(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          dispatch({
            type: LOGOUT_SUCCESS,
          });
        } else {
          dispatch(
            returnErrors(
              err.response.data,
              err.response.status,
              'ADD_CATEGORY_ERROR'
            )
          );
        }
        reject(err);
      });
  });
};

export const getOneCategory = (id) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/category/get/${id}`, tokenConfig(getState))
      .then((res) => {
        console.log({ res });
        resolve(res.data.data.category);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch({
            type: LOGOUT_SUCCESS,
          });
        } else {
          reject(err);
        }
      });
  });
};

export const getCategoryTypes = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/category-type/get', tokenConfig(getState))
      .then((res) => {
        console.log(res.data.data.categoryTypes);
        resolve(res.data.data.categoryTypes);
      })
      .catch((err) => {
        console.log(err);
        resolve([]);
      });
  });
};

export const getAllCategories =
  (page, status, search) => (dispatch, getState) => {
    console.log('inside actions', status);
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/category/get?page=${page}&status=${status}&query=${search}`,
          tokenConfig(getState)
        )
        .then((res) => {
          dispatch({
            type: GET_CATEGORIES,
            payload: res.data,
          });
          resolve(true);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            dispatch({
              type: LOGOUT_SUCCESS,
            });
          }
          reject(err);
        });
    });
  };

export const deleteCategory = (id) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/api/category/delete/${id}`, tokenConfig(getState))
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch({
            type: LOGOUT_SUCCESS,
          });
        } else {
          reject(err);
        }
      });
  });
};

export const setCategory = () => {
  return {
    type: CATEGORY_LOADING,
  };
};
