import React, { useState, useCallback } from 'react';
import {
  Card,
  Container,
  Row,
  CardBody,
  Col,
  Button,
  Form,
  Input,
  Label,
} from 'reactstrap';
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getCategoryTypes,
  updateCategory,
  getOneCategory,
} from '../../actions/categoryActions';
import SpinnerLoader from 'components/Misc/Spinner';
import { useHistory } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { updateToast, successToast, errorToast } from 'shared/constants';
import { MultiSelect } from 'react-multi-select-component';
import Select2 from 'react-select2-wrapper';

function EditCategory({ getCategoryTypes, updateCategory, getOneCategory }) {
  const [showSpinner, setSpinner] = useState(false);
  const [title, setTitle] = useState('');
  const [categoryTypes, setCategoryTypes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [type, setType] = useState('TASK');

  const planners = [
    { label: 'Standard', value: 'standard' },
    { label: 'Religious', value: 'religious' },
  ];

  const [selectedPlanner, setPlanners] = useState([planners[0]]);

  const params = useParams();
  const id = params.id;

  const history = useHistory();

  React.useEffect(() => {
    getOneCategory(id).then((category) => {
      console.log({ category });
      setSelectedCategory(category?.categoryType);
      setTitle(category?.title);
      setType(category?.type);
      let sl_planners = [];
      if (category?.isStandard) {
        sl_planners.push({ label: 'Standard', value: 'standard' });
      }
      if (category?.isReligious) {
        sl_planners.push({ label: 'Religious', value: 'religious' });
      }
      setPlanners(sl_planners);
    });
    getCategoryTypes().then((categoryTypes) => {
      setCategoryTypes(categoryTypes);
      setSelectedCategory(categoryTypes.length ? categoryTypes[0]._id : '');
    });
  }, []);

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleCategoryType = (categoryName) => {
    setSelectedCategory(categoryName);
  };

  const handleValueRendere = (selected) => {
    return selected.map((val) => (
      <span className='dflt-badge tbl-txt mx-1'>{val.label}</span>
    ));
  };

  const customItemRenderer = ({ checked, option, onClick, disabled }) => {
    return (
      <div className={`${disabled && 'disabled'} w-100`}>
        <div className='d-flex justify-content-between w-100'>
          <div>
            <span>{option.label}</span>
          </div>
          <label className='cstm-chkbox'>
            <input
              type={'checkbox'}
              onChange={onClick}
              checked={checked}
              tabIndex={-1}
              disabled={disabled}
            />
          </label>
        </div>
      </div>
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!selectedCategory) {
      toast.error('Category Icons is required', errorToast);
      return;
    }

    if (!selectedPlanner.length) {
      toast.error('Please select atleast one planner', errorToast);
      return;
    }

    const newCategory = {
      title,
      type,
      categoryType: selectedCategory,
      isStandard: selectedPlanner.some(
        (planner) => planner.value === 'standard'
      ),
      isReligious: selectedPlanner.some(
        (planner) => planner.value === 'religious'
      ),
    };
    setSpinner(true);
    console.log(newCategory);
    updateCategory(id, newCategory)
      .then((res) => {
        setSpinner(false);
        toast.success('Category added successfully', successToast);
        if (history.location.pathname.startsWith('/admin/categories')) {
          history.push('/admin/categories');
        }
      })
      .catch((err) => {
        console.log('Error: ', err);
        toast.error('Something wents wrong.', errorToast);
        setSpinner(false);
      });
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <SimpleHeader
        name='Edit'
        btn_name='Edit'
        updateList={() => console.log('')}
      />
      <Container className='mt--4 ml-3 float-left ctgry-container'>
        <Form onSubmit={(e) => onSubmit(e)}>
          <Row>
            <div className='col'>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Label className='secondary-label'>
                        Select Category Icon*
                      </Label>
                    </Col>
                    <Col md={12} className='mt-2'>
                      <div className='d-flex'>
                        {categoryTypes?.length
                          ? categoryTypes.map((category) => (
                              <div
                                key={category._id}
                                className={`ctgry-img-wrapper mx-2 ${
                                  selectedCategory === category._id
                                    ? 'sl-ctgry'
                                    : ''
                                }`}
                                onClick={() => handleCategoryType(category._id)}
                              >
                                {selectedCategory === category._id && (
                                  <img
                                    src='/assets/image/sl-ctgry.svg'
                                    alt='sl-ctgry'
                                    className={`sl-ctgry-icon w-20`}
                                  />
                                )}
                                <img src={category.url} alt={category.name} />
                              </div>
                            ))
                          : ''}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
          <Row className='mt--3'>
            <div className='col'>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Label className='secondary-label'>Title*</Label>
                      <Input
                        type='text'
                        name='address'
                        className='input-secondary-style'
                        value={title}
                        onChange={handleTitle}
                        required
                      />
                    </Col>
                    <Col md={12} className={'mt-3'}>
                      <Label className='secondary-label'>Select Planner*</Label>
                      <MultiSelect
                        options={planners}
                        hasSelectAll={false}
                        value={selectedPlanner}
                        onChange={setPlanners}
                        ClearIcon={''}
                        ClearSelectedIcon={false}
                        overrideStrings={{
                          allItemsAreSelected: selectedPlanner.map(
                            (val) => val.label
                          ),
                        }}
                        valueRenderer={handleValueRendere}
                        ItemRenderer={customItemRenderer}
                        disableSearch
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className={'mt-3'}>
                      <Label className='secondary-label'>Select Type</Label>
                      <Select2
                        value={type}
                        className='form-control'
                        onSelect={(e) => setType(e.params.data.id)}
                        data={[
                          { text: 'Task', id: 'TASK' },
                          { text: 'List', id: 'LIST' },
                        ]}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
          <Row>
            <Col md={12}>
              <Button
                className='default-button-background default-button-style'
                type='submit'
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      <Toaster />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryTypes: () => dispatch(getCategoryTypes()),
    getOneCategory: (id) => dispatch(getOneCategory(id)),
    updateCategory: (id, category) => dispatch(updateCategory(id, category)),
  };
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    error: state.error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);
