// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux'

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

function Home({auth}){

    console.log(auth)

    return (
        <>
            <BrowserRouter>
                <Switch>
                    {!auth.isAuthenticated ?
                    <Route path="/" render={(props) => <AuthLayout {...props} />} />
                    :
                    auth?.user?.isAdmin && <Route path="/" render={(props) => <AdminLayout {...props} />} />
                    }
                </Switch>
            </BrowserRouter>
        </>
    )

}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps)(Home)