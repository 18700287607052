import React, { useState } from "react";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Card, Button, Container, Row, Col, Input, Form, CardBody } from "reactstrap";
import { successToast, errorToast } from "shared/constants";
import SpinnerLoader from "components/Misc/Spinner";
import toast, { Toaster } from "react-hot-toast";
import { passwordRegex } from "shared/constants";

import { connect } from 'react-redux';
import { changePassword } from '../../actions/authActions'

const ChangePassword = ({ changePassword }) => {
  //form values states
  const [oldPass, setOld] = useState("");
  const [newPass, setNew] = useState("");
  const [confirmPass, setConfirm] = useState("");

  //validation states
  const [showError, setErrorMessage] = useState(false);
  const [showSpinner, setSpinner] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (!oldPass || !newPass || !confirmPass) {
        setErrorMessage(true);
        return;
      }
      if (newPass !== confirmPass) {
        toast.error("New password and Confirm password must be same.", errorToast);
        return;
      }
      if(newPass.length >= 6){
        setSpinner(true);
        changePassword({
          password: oldPass,
          newPassword: newPass,
          confirmPassword: confirmPass
        })
          .then((data) => {

            if (data.message) {
              toast.success("Password Updated Successfully!", successToast);
              setNew("");
              setOld("");
              setConfirm("");
            } else {
              toast.error(data?.error, errorToast);
            }
            setSpinner(false);
          })
          .catch((err) => {
            console.log(err);
            setSpinner(false);
            toast.error("Something went wrong!", errorToast);
          });
      }
      else{
        toast.error("Password must contain at least 6 characters", errorToast)
        setSpinner(false)
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong", errorToast);
    }
    
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <SimpleHeader name="Change Password" />
      <div className="mt--4 float-left w-60 ml-3">
        <Row>
          <div className="col-lg-7 ml-4 col-md-12">
            <Card>
              <CardBody className="px-5">
                <Form action="submit" onSubmit={(e) => handleSubmit(e)}>
                  <Row className="pt-3">
                      <Col md={12}>
                          <p className="card-heading-txt-style">Change Password</p>
                      </Col>
                  </Row>
                  <Row className="mt-25">
                    <Col lg="12">
                      <h4 className="secondary-label">Current Password</h4>
                      <Input
                        className={`input-primary-style ${!newPass && showError ? "is-invalid" : ""}`}
                        placeholder=" Enter current password"
                        type="password"
                        value={oldPass}
                        onChange={(e) => setOld(e.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col lg="12">
                      <h4 className="secondary-label">New Password</h4>
                      <Input
                        className={`input-primary-style ${!newPass && showError ? "is-invalid" : ""}`}
                        type="password"
                        placeholder="Enter new password"
                        value={newPass}
                        onChange={(e) => setNew(e.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col lg="12">
                      <h4 className="secondary-label">Confirm Password</h4>
                      <Input
                        className={`input-primary-style ${!newPass && showError ? "is-invalid" : ""}`}
                        placeholder="Re-enter new password"
                        type="password"
                        value={confirmPass}
                        onChange={(e) => setConfirm(e.target.value)}
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4 mb-3"> 
                    <Col className="pt-3">
                      <Button  className="default-button-background default-button-style" type="submit" >
                          Update
                      </Button>
                    </Col>
                  </Row>
                </Form>
                
              </CardBody>
            </Card>
          </div>
        </Row>
      </div>
      <Toaster />
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    changePassword: (passwords) => dispatch(changePassword(passwords))
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
