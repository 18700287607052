import classnames from "classnames";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
} from "reactstrap";
import { logout } from '../../actions/authActions'
import { connect } from 'react-redux'

function AdminNavbar({ theme, logout, auth }) {
  const history = useHistory();
  const [showBack, setBack] = useState(false);
  const handleLogout = (e) => {
    e.preventDefault();
    logout()
      .then(() => {
        history.push("/auth/login");
      })
      .catch((err) => {
        console.log(err);
        history.push("/auth/login");
      });
  };

  useEffect(() => {
    displayBackButton(history.location);
    return history.listen((location, action) => {
      displayBackButton(location);
    });
  }, []);

  const displayBackButton = (location) => {
    if (
      location.pathname.startsWith("/admin/categories/edit") ||
      location.pathname.startsWith("/admin/categories/add") ||
      location.pathname.startsWith("/admin/quotes/add") ||
      location.pathname.startsWith("/admin/quotes/edit")
      
    ) {
      setBack(true);
    } else {
      setBack(false);
    }
  };

  const handleBackButton = () => {
    if (
      history.location.pathname.startsWith("/admin/quotes/add") ||
      history.location.pathname.startsWith("/admin/quotes/edit")
    ) {
      history.push("/admin/quotes");
    } else if (
      history.location.pathname.startsWith("/admin/jobs/job-categories")
    ) {
      history.push("/admin/jobs");
    } else if( history.location.pathname.startsWith("/admin/categories/add") || history.location.pathname.startsWith("/admin/categories/edit"))  {
      history.push("/admin/categories")
    } else if (history.location.pathname.startsWith('/admin/notifications/')) {
      history.push("/admin/notifications")
    }
  }

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand-end",
          { "navbar-dark bg-info-bh": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" },
          { "navbar-expand-space": showBack === true }
        )}
      >
        {showBack && (
          <div
            role="button"
            className="mx-5 text-white back-btn"
            onClick={() => handleBackButton()}
          >
            <i className="fa fa-arrow-left mr-3"></i>Back
          </div>
        )}
        <div className="mx-5">
          <UncontrolledDropdown nav>
            <DropdownToggle
              role="button"
              className="nav-link pr-0"
              color=""
              tag="a"
            >
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle bg-white">
                  <img
                  src='/profile.svg'
                  size='15px'
                  alt='i'
                  />
                </span>
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm text-white font-weight-bold">
                    {auth?.data?.user?.name}
                    <i className="ml-2 fa fa-chevron-down"></i>
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={(e) => history.push("/admin/change-password")}
              >
                <img
                src='/change-password.svg'
                className="mr-3"
                alt='i'
                sizes="15px"
                />
                <span className="dropdown-item-style">Change Password</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={(e) => handleLogout(e)}>
                <img
                src='/logout.svg'
                className="mr-3"
                alt='i'
                sizes="15px"
                />
                <span className="dropdown-item-style">Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      error: state.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
      logout: () => dispatch(logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar)