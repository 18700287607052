import React from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { forgot_password } from '../../actions/authActions';
import { connect } from 'react-redux';
import { errorToast } from "shared/constants";
import SpinnerLoader from "components/Misc/Spinner";

function ForgotPassword({forgot_password}) {
  const history = useHistory();

  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [showSpinner, setSpinner] = React.useState(false);

  const handleSubmit = async () => {
    if(email){
      setSpinner(true);
      forgot_password(email)
        .then((data) => {
          history.push('/auth/email-sent')
          setSpinner(false);
        })
        .catch(() => {
          toast.error("Invalid email address", errorToast);
          setSpinner(false);
        });
    }
    else{
      toast.error("Email field is required.", errorToast)
    }
  };

  const keyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      <SpinnerLoader adminView={true} showSpinner={showSpinner} />
      
      <div className="w-790 h-600 auth-form">
        <Container className="h-100">
          <Card className="bg-default-login-card border-0 mb-0 h-100">
            <CardBody className="px-lg-6 py-lg-3">
              <div className="d-flex justify-content-center pt-5">
                <img src="../assets/image/forgot-password.svg"/>
              </div>
              <div className="text-center text-muted mt-4 mb-4">
                <h1 className="gotham-bold">Forgot Password?</h1>
                <p className="gotham-normal">Enter your email below to recieve password reset instructions.</p>
              </div>
              <Form role="form">
                <FormGroup
                  className={classnames("mb-3", {
                    focused: focusedEmail,
                  })}
                >
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="input-icon">
                        <img
                          src="/mail-icon.svg"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email Address"
                      className="input-primary-style"
                      type="email"
                      onFocus={() => setfocusedEmail(true)}
                      onBlur={() => setfocusedEmail(true)}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={keyDown}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-right">
                  <small
                    onClick={(e) => history.push("/auth/login")}
                    className="black-color px- small-text"
                    role={"button"}
                  >
                    Back to login
                  </small>
                </div>
                <div className="text-center">
                  <Button
                    className="default-button-background default-button-style mt-2 mb-3 btn-block"
                    color="info"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Reset Password
                  </Button>
                </div>
                
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
      <Toaster />
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    forgot_password: (email) => dispatch(forgot_password(email))
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      error: state.error
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
