import {
    QUOTES_LOADING,
    GET_QUOTES,
    UPDATE_QUOTE,
    LOGOUT_SUCCESS,
    DELETE_QUOTE,
    ADD_QUOTE,
} from './types'
import axios from 'axios'
import { tokenConfig } from './configToken'
import { returnErrors, retrunSuccess } from './errorActions'
   
export const editQuote= (id, quote) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        axios.put(`/api/quotes/edit/${id}`, quote, tokenConfig(getState))
        .then(res =>{
            dispatch({
            type: UPDATE_QUOTE
            })
            resolve(true)
        }
        )
        .catch(err => {
            console.log(err)
            if(err.response.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            else{
                dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_USER_ERROR'))
            }
            reject(err)
        })
    })
}

export const addQuote= (quote) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        axios.post(`/api/quotes/add`, quote, tokenConfig(getState))
        .then(res =>{
            dispatch({
            type: ADD_QUOTE
            })
            resolve(true)
        }
        )
        .catch(err => {
            console.log(err)
            if(err.response.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            else{
                dispatch(returnErrors(err.response.data, err.response.status, 'ADD_QUOTE_ERROR'))
            }
            reject(err)
        })
    })
}

export const deleteQuote = (id) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/api/quotes/delete/${id}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: DELETE_QUOTE,
                payload: res.data
            })
            resolve(true)
        })
        .catch(err => {
            if(err?.response?.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            reject(err)
        })
    })
}

export const getQuote = (id) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        axios.get(`/api/quotes/get/${id}`,
        tokenConfig(getState))
        .then(res =>{
            resolve(res.data.data)
        })
        .catch(err => {
            if(err?.response?.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            reject(err)
        })
    })
}

   
export const getAllQuotes = (page, search) => (dispatch, getState) => {
    dispatch(setQuote())
    return new Promise((resolve, reject) => {
        axios.get(`/api/quotes/get?page=${page}&query=${search}`,
        tokenConfig(getState))
        .then(res =>{
            dispatch({
            type: GET_QUOTES,
            payload: res.data
            })
            resolve(true)
        })
        .catch(err => {
            if(err?.response?.status === 401){
                dispatch({
                    type: LOGOUT_SUCCESS
                })
            }
            reject(err)
        })
    })
}
   
export const setQuote = () => {
    return {
        type: QUOTES_LOADING
    }
}