import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Badge
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SpinnerLoader from "components/Misc/Spinner";
import { getAllNotifications, deleteNotification } from '../../actions/notificationActions'
import DeleteModal from "components/Modals/deleteModal";
import { connect } from 'react-redux';
import { NOTIFICATION_STATUS } from '../../shared/constants'
import moment from 'moment-timezone';

import { useHistory } from 'react-router-dom'
import Select2 from "react-select2-wrapper";
import toast, { Toaster } from "react-hot-toast";
import {
  successToast,
  errorToast,
  notificationDeleteMessage
} from "shared/constants";

function Notifications({ notifications, getAllNotifications, deleteNotification }) {
  const [totalPages, setTotalPages] = useState(notifications?.totalPages)
  const [currentPage, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [searchFocus, setSearchFocus] = useState(false)
  const [deleteModal, showDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const history = useHistory();

  useEffect(() => {
    fetchNotifications(
      {
        page: currentPage,
        search: search
      }
    )
  }, []);

  const fetchNotifications = async (body) => {
    setSpinner(true);
    getAllNotifications(body.page, body.search)
    .then(data => {
      console.log(data)
      setPage(notifications.currentPage)
      setTotalPages(notifications.totalPages)
      setSpinner(false)
    })
    .catch(err => {
      console.log("Error: ", err)
    })
  }

  const pages = () => {
    let pagesArr = [];
    for (let i = 1; i <= notifications.totalPages; i++) {
      pagesArr.push(
        <PaginationItem className={notifications.currentPage === i ? "active" : ""}>
          <PaginationLink onClick={(e) => handlePageClick(e, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }
    return pagesArr;
  };

  const handlePageClick = (e, pageNumber) => {
    e.preventDefault();
    fetchNotifications({page: pageNumber, search: search})
    setPage(pageNumber);
  };

  const handleSearch = (val) => {
    setSearch(val);
    fetchNotifications({page: currentPage, search: val})
  };

  const handleEditNotification = (e, id) => {
    if( history.location.pathname.startsWith("/admin/notifications")){
      history.push(`/admin/notifications/edit/${id}`)
     } else if (history.location.pathname.startsWith("/manager/notifications")) {
       history.push(`/manager/notifications/edit/${id}`)
     }
  }

  const handleDeleteNotification = async (e, notificationId) => {
    setDeleteID(notificationId)
    showDeleteModal(true)
  }

  const handleDelete = () => {
    setSpinner(true);
    deleteNotification(deleteID)
    .then(res => {
      fetchNotifications(
        {
          page: currentPage,
          search: search
        }
      )
      setSpinner(false)
      toast.success("Notification Deleted Successfuly", successToast);
      showDeleteModal(!deleteModal)
    })
    .catch(err => {
      setSpinner(false)
      toast.error("Something went wrong", errorToast);
      showDeleteModal(!deleteModal)
    })
  };

  const handleDeleteClose = () => {
    setDeleteID("");
    showDeleteModal(!deleteModal);
  };

  return (
    <>
      <SpinnerLoader showSpinner={showSpinner} />
      <SimpleHeader
        name="Manage Notifications"
        btn_name="Send New Notification"
      />
      <Container className="mt--4" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <div className="d-flex align-content-center justify-content-between">
                  <div className="search-field">
                    <InputGroup className="search-group">
                      <InputGroupText className={`search-icon`}>
                        <i className="fas fa-search fa-12x"></i>
                      </InputGroupText>
                      <Input
                        className="d-inline-block searchBox"
                        placeholder="Search..."
                        type="text"
                        value={search}
                        onFocus={() => setSearchFocus(true)}
                        onBlur={() => setSearchFocus(false)}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                </div>
              </CardHeader>
              <div className="table-responsive">
                <Table className="dataTable align-items-center">
                  <thead className="thead-bh icon-color-light">
                    <tr>
                      <th className="px-5 w-5" scope="col">
                        Sr#
                      </th>
                      <th className="px-5 w-20" scope="col">
                        Title
                      </th>
                      <th className="px-0 w-20" scope="col">
                        Description
                      </th>
                      <th className="px-0 w-20" scope="col">
                        Date
                      </th>
                      <th className="px-0 w-10" scope="col">
                        Status
                      </th>
                      <th className="px-0 w-5" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {notifications?.notifications?.map((notification, index) => (
                      <tr key={index}>
                        <td className="pl-5 overflowStyle tbl-txt txt-capitalize">
                          {(notifications.currentPage*10)-(10-index-1)}
                        </td>
                        <td className="pl-5 overflowStyle tbl-txt txt-capitalize">
                            {notification?.title}
                        </td>
                        <td className="default-color overflowStyle pl-0 pr-4 tbl-txt txt-capitalize">
                            {notification?.description}
                        </td>
                        <td className="pl-0 pr-4 overflowStyle tbl-txt">
                          {`${moment(notification?.dateTime).local().format("hh:mm A")}, ${moment(notification?.dateTime).format("DD/MM/YYYY")}`}
                        </td>
                        <td className="pl-0 pr-4 overflowStyle tbl-txt">
                          {notification?.status ? <span className="success-color">Sent</span> : <span className="danger-color">Pending</span>}
                        </td>
                        <td className="actionDropdown px-0">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className={`btn-icon-only text-light action-bg`}
                              color=""
                              role="button"
                              size="sm"
                            >
                              <i className="fas fa-ellipsis-h action-dot" />
                            </DropdownToggle>
                            <DropdownMenu right className="mw-210">
                              <DropdownItem
                              onClick={(e) => handleEditNotification(e, notification._id)}
                              disabled={notification?.status}
                              >
                                <div className="d-flex align-items-center justify-content-start">
                                  <img src="/edit.svg" size='15px' alt="i"/>
                                  <div className="pl-2 action-txt">Edit</div>
                                </div>
                              </DropdownItem>
                              <DropdownItem divider/>
                              <DropdownItem
                                onClick={(e) => handleDeleteNotification(e, notification._id)}
                                >
                                <div className="d-flex align-items-center justify-content-start">
                                  <span className="x-icon">X</span>
                                  <div className="pl-2 action-txt">Delete</div>
                                </div>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                    <tr></tr>
                  </tbody>
                </Table>
                {!showSpinner && !notifications?.notifications?.length && (
                  <div className="d-flex align-items-center justify-content-center mb-3">
                    No records found
                  </div>
                )}
              </div>
              <CardFooter className="py-4">
                <nav
                  className="d-flex align-items-center justify-content-between"
                  aria-label="..."
                >
                  <div className="pageEntry">
                  </div>
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                      className={notifications.currentPage === 1 ? "disabled" : ""}
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, notifications.currentPage - 1)}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {pages().map((page, index) => (
                      <div key={index}>{page}</div>
                    ))}
                    <PaginationItem
                      className={
                        notifications.currentPage >= notifications.totalPages
                          ? "disabled"
                          : ""
                      }
                    >
                      <PaginationLink
                        onClick={(e) => handlePageClick(e, notifications.currentPage + 1)}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
        <DeleteModal
        open={deleteModal}
        handleClose={handleDeleteClose}
        handleDelete={handleDelete}
        message={notificationDeleteMessage}
        title={"Delete Notification History?"}
        />
      </Container>
      <Toaster/>
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getAllNotifications: (page, search) => dispatch(getAllNotifications(page, search)),
    deleteNotification: (id) => dispatch(deleteNotification(id))
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      jobs: state.jobs,
      notifications: state.notifications
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
