import React, { useEffect } from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { login } from '../../actions/authActions';
import { connect } from 'react-redux';
import { errorToast, updateToast } from "shared/constants";
import SpinnerLoader from "components/Misc/Spinner";

function Login({login}) {
  const history = useHistory();

  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showSpinner, setSpinner] = React.useState(false);

  const handleLogin = async () => {
    setSpinner(true);
    if (!email || !password) {
      toast.error("Provide Credentials to Continue", updateToast);
      setSpinner(false);
      return;
    }
    login({email, password})
      .then((res) => {
        setSpinner(false);
      })
      .catch(() => {
        toast.error("Invalid Email or Password", errorToast);
        setSpinner(false);
      });
  };

  const keyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin();
    }
  };

  return (
    <>
      <SpinnerLoader adminView={true} showSpinner={showSpinner} />
      <div className="w-790 h-600 auth-form">
        <Container className="h-100">
          <Card className="bg-default-login-card border-0 mb-0 h-100">
            <CardBody className="px-6-8 py-lg-3">
              <div className="d-flex justify-content-center mt-2">
                <p className="pt-5 gotham-medium">Welcome!</p>
              </div>
              <div className="text-center text-muted mt-4 mb-5">
                <img src="../assets/image/dd-logo.svg"/>
              </div>
              <Form role="form">
                <FormGroup
                  className={classnames("mb-3", {
                    focused: focusedEmail,
                  })}
                >
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="input-icon">
                        <img
                          src="/mail-icon.svg"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email Address"
                      className="input-primary-style"
                      type="email"
                      onFocus={() => setfocusedEmail(true)}
                      onBlur={() => setfocusedEmail(true)}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={keyDown}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames("mb-1", {
                    focused: focusedPassword,
                  })}
                >
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="dd-input-text-border input-icon">
                        <img
                          src="/lock-icon.svg"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      className="input-primary-style"
                      onFocus={() => setfocusedPassword(true)}
                      onBlur={() => setfocusedPassword(true)}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={keyDown}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-right mt-2">
                  <small
                    onClick={(e) => history.push("/auth/forgot-password")}
                    className="black-color px-2 small-text py-2"
                    role={"button"}
                  >
                    Forgot Password?
                  </small>
                </div>
                
                <div className="text-center mt-38">
                  <Button
                    className="default-button-background default-button-style btn-block mt-2 mb-3"
                    color="info"
                    type="button"
                    onClick={handleLogin}
                  >
                    Log In
                  </Button>
                </div>
                
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
      <Toaster />
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
      login: (user) => dispatch(login(user))
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      error: state.error
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
