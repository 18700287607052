export const customIcons = {
  play: <i className="fa fa-play playIcon"></i>,
  pause: <i className="fa fa-pause playIcon"></i>,
};

export const defaultPassword = {
  password: "password",
  confirmPassword: "password"
}

export const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

export const defaultJobCategories = {
  title: "",
  image: "",
  imageObj: {
    data: '',
    url: '',
    fileName: 'No File Chosen',
    contentType: ''
  }
}

export const updateToast = {
  className: "updateToast",
  iconTheme: {
    primary: "white",
    secondary: "#3a3a73",
  },
};

export const successToast = {
  className: "successToast",
  iconTheme: {
    primary: "white",
    secondary: "#76d89c",
  },
};

export const errorToast = {
  className: "errorToast",
  iconTheme: {
    primary: "white",
    secondary: "red",
  },
};

export const JOB_STATUSES = {
  active: "ACTIVE",
  cancelled: "CANCELLED",
  hired: "HIRED",
  completed: "COMPLETED",
  declined: "DECLINED",
};

export const CATEGORY_STATUSES = {
  standard: 'standard',
  religious: 'religious'
}

export const USER_STATUSES = {
  blocked: 'blocked',
  unblocked: 'unblocked'
}

export const NOTIFICATION_SEND_TO_ENUM = {
  customers: "CUSTOMERS",
  vendors: "VENDORS",
  everyone: "EVERYONE",
};

export const NOTIFICATION_STATUS = {
  sent: "SENT",
  scheduled: "SCHEDULED"
}

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD"

export const managerDeleteMessage = `Do you want to remove this manager.`

export const notificationDeleteMessage = `Are you sure you want to delete the notification history?`

export const fontClasses = `<style> * {font-family: sans-serif;} .ql-font-serif, .ql-font-serif * { font-family: serif !important; } .ql-font-san-serif, .ql-font-san-serif * { font-family: sans-serif !important; } .ql-font-monospace, .ql-font-monospace * { font-family: monospace !important; } </style>`;
