import {
    UPDATE_USERS,
    USERS_LOADING,
    GET_USERS
} from '../actions/types'

const initialState = {
    users: [],
    currentPage: 1,
    totalPages: 0,
    loading: false
}

export default function categoryReducer(state=initialState, action) {
    switch(action.type) {
        case GET_USERS:
            return {
                ...state,
                users: action.payload.data.users,
                currentPage: Number(action.payload.data.metadata.currentPage),
                totalPages: Number(action.payload.data.metadata.totalPages),
                loading: false
            };
        case UPDATE_USERS:{
            return {
                ...state
            }
        };
        case USERS_LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            return state
    }
}