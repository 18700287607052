
export const GET_ERROR = 'GET_ERROR';
export const GET_SUCCESS = "GET_SUCCESS"
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED= "USER_LOADED";

// auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

// Token
export const TOKEN_VALID = 'TOKEN_VALID'
export const TOKEN_INVALID = 'TOKEN_INVALID'
export const CHECKING_TOKEN = 'CHECKING_TOKEN'

// User
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL'

// Categories
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const CATEGORY_LOADING = 'CATEGORY_LOADING'
export const ADD_CATEGORY = 'ADD_CATEGORY'


// Users
export const GET_USERS = 'GET_USERS'
export const UPDATE_USERS = 'UPDATE_USERS'
export const USERS_LOADING = 'USERS_LOADING'

// Quotes
export const GET_QUOTES = 'GET_QUOTES'
export const ADD_QUOTE = 'ADD_QUOTE'
export const UPDATE_QUOTE = 'UPDATE_QUOTE'
export const DELETE_QUOTE = 'DELETE_QUOTE'
export const QUOTES_LOADING = 'QUOTES_LOADING'

// Notifications
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING'

// Budget
export const GET_BUDGET = 'GET_BUDGET'
export const BUDGET_LOADING = 'BUDGET_LOADING'


