import React, { useEffect } from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { useHistory, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { errorToast, successToast } from "shared/constants";
import { validate_reset_link, changePasswordWithLink } from '../../actions/authActions';
import { connect } from 'react-redux';
import SpinnerLoader from "components/Misc/Spinner";

function ResetPassword({validate_reset_link, changePasswordWithLink}) {
  const history = useHistory();
  const { id } = useParams();

  // useEffect(() => {
  //   id ? checkResetLink() : setSpinner(false);
  // }, []);

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showSpinner, setSpinner] = React.useState(false);
  const [validLink, setValidLink] = React.useState(false);

  const checkResetLink = () => {
    validate_reset_link(id)
      .then((data) => {
        data
          ? setValidLink(true)
          : toast.error("Link not Valid", errorToast);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        toast.error("Something went wrong", errorToast);
      });
  };

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not Match!", errorToast);
      return;
    }
    if (password.length < 6) {
      toast.error("Passwords must be atleast 6 characters", errorToast);
      return;
    }
    setSpinner(true);
    console.log(id)
    changePasswordWithLink(password, confirmPassword, id)
      .then((data) => {
        console.log(data)
        data
          ? toast.success("Password reset Successfully!", successToast)
          : toast.error("Password Reset Failed", errorToast);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        toast.error("Invalid Token", errorToast);
      });
  };

  return (
    <>
      <SpinnerLoader adminView={true} showSpinner={showSpinner} />
      <div className="w-790 h-600 auth-form">
        <Container className="mt-n13 pb-5 h-100">
          <Card className="bg-default-login-card border-0 mb-0 h-100">
            <CardBody className="px-lg-6 py-lg-3">
              <div className="d-flex justify-content-center pt-4">
                <img src="/assets/image/forgot-password.svg"/>
              </div>
              <div className="text-center text-muted mt-4 mb-4">
                <h1 className="default-color">Reset Password</h1>
              </div>
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="dd-input-text-border input-icon">
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="New Password"
                      className="input-primary-style"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="dd-input-text-border input-icon">
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Confirm Password"
                      type="password"
                      className="input-primary-style"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="default-button-background default-button-style my-2 btn-block"
                    color="info"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Reset Password
                  </Button>
                </div>
                <div className="text-center">
                  <small
                    onClick={(e) => history.push("/auth/login")}
                    className="black-color px-2 small-text py-2"
                    role={"button"}
                  >
                    Back to login
                  </small>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
      <Toaster />
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    validate_reset_link: (link) => dispatch(validate_reset_link(link)),
    changePasswordWithLink: (password, confirmPassword, link) => dispatch(changePasswordWithLink(password, confirmPassword, link))
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      error: state.error
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
