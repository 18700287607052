import { combineReducers } from 'redux'
import authReducer from './authReducer'
import errorReducer from './errorReducer'
import categoryReducer from './categoryReducer'
import userReducer from './userReducer'
import quoteReducer from './quoteReducer'
import notificationReducer from './notificationReducer'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig={
    key: "root",
    storage,
    whitelist: ['auth']
}

const rootReducer = combineReducers({
    auth: authReducer,
    categories: categoryReducer,
    error: errorReducer,
    users: userReducer,
    quotes: quoteReducer,
    notifications: notificationReducer
})

export default persistReducer(persistConfig, rootReducer)